import { Route } from '@angular/router';

export enum AppRoutesEnum {
  MAIN = '',
  LOGIN = 'login',
  PASSWORD_RECOVERY = 'password-recovery',
  REGISTRATION = 'registration',
  PAGE_NOT_FOUND = '404',
  BACKEND_ERROR_PAGE = '500',
}

export const appRoutes: Route[] = [
  { path: AppRoutesEnum.MAIN, loadComponent: () => import('./pages/start-page/start-page.component').then(c => c.StartPageComponent) },
  { path: AppRoutesEnum.LOGIN, loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent) },
  {
    path: AppRoutesEnum.REGISTRATION,
    loadComponent: () => import('./pages/registration/registration.component').then(c => c.RegistrationComponent),
  },
  {
    path: AppRoutesEnum.PASSWORD_RECOVERY,
    loadComponent: () => import('./pages/password-recovery/password-recovery.component').then(c => c.PasswordRecoveryComponent),
  },
  {
    path: AppRoutesEnum.PAGE_NOT_FOUND,
    loadComponent: () => import('./pages/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent),
  },
  {
    path: AppRoutesEnum.BACKEND_ERROR_PAGE,
    loadComponent: () => import('./pages/page-500/page-500.component').then(c => c.Page500Component),
  },
  /*TODO убрать, когда бек у себя поправит генератор ссылки на сброс пароля (смс/email)*/
  {
    path: 'change_password',
    redirectTo: AppRoutesEnum.PASSWORD_RECOVERY,
  },
  {
    path: '',
    loadComponent: () => import('./pages/app-manager/app-manager.component').then(c => c.AppManagerComponent),
    loadChildren: () => import('./pages/app-manager/app-manager.routes').then(m => m.appManagerRoutes),
  },
  { path: '**', redirectTo: AppRoutesEnum.PAGE_NOT_FOUND },
];
